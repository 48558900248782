import React, { useEffect } from 'react';
import CountUp from 'react-countup';
import './milestonehome.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Milestonehome = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div className="containerHighlightMilestone" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2500">
        <div className="company-milestone" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2500">
          <h3 data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2500">
            Pencapaian Perusahaan
          </h3>
          <p data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2500">
            Berkomitmen mengembangi Infrastruktur terbaik
          </p>
          <div className="d-flex justify-content-around flex-wrap flex-md-nowrap" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2500">
            <div className="boxmile col-md-4 col-11 mt-md-0 mt-3" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2500">
              <div>
                <h2>
                  <CountUp className="amount" start={0} end={11000} duration={8} separator=" " />
                  KM
                </h2>
                <h4>Jaringan Yang Sudah Tersebar</h4>
              </div>
            </div>
            <div className="boxmile col-md-4 col-11 mt-md-0 mt-3 mx-md-2" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2500">
              <div>
                <h2>
                  <CountUp className="amountyear" start={0} end={20} duration={13} separator=" " />
                  Th
                </h2>
                <h4 className="year">Tahun</h4>
              </div>
            </div>
            <div className="boxmile col-md-4 col-11 mt-md-0 mt-3" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2500">
              <div>
                <h1>
                  <CountUp className="amount" start={0} end={170} duration={8} separator=" " />
                </h1>
                <h4>POP</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Milestonehome;
