import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import './dewankomisaris.scss';
import 'aos/dist/aos.css';
import axios from 'axios';
import { PuffLoader } from 'react-spinners';

const DewanKomisaris = () => {
  const [newData, setNewData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1300,
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const response = await axios.get('https://content.remala.id/wp-json/wp/v2/id-struktur?order=asc');
        if (response.status === 200) {
          const komisarisData = response.data.filter((item) => item.acf && item.acf.tipe_jabatan === 'komisaris').map((item, index) => ({ ...item.acf, key: index + 1 }));

          const formattedData = komisarisData.map((item) => ({
            id: item.key,
            nama_lengkap: item.nama_lengkap,
            jabatan: item.jabatan,
            foto_profile: item.foto_profile.url,
            sejarah_karir: item.sejarah_karir,
            sejarah_pendidikan_terakhir: item.sejarah_pendidikan_terakhir,
            status_posting: item.status_posting,
          }));
          setLoading(false);
          setNewData(formattedData.filter((data) => data.status_posting === true));
        }
      } catch (error) {
        setLoading(false);
        console.error('Terjadi kesalahan:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <div className="loading-spinner-news">
          <PuffLoader color="#007bff" size={100} margin={2} />
        </div>
      ) : (
        <div className="dewan-komisaris-container">
          {newData.map((profile, index) => (
            <div className="col-profile d-flex mt-5" data-aos="fade-left" key={index}>
              <div className="col-6 profile-box">{profile.foto_profile && <img src={profile.foto_profile} alt={profile.nama_lengkap} />}</div>
              <div className="mx-4">
                <div className="nameDivision">
                  <h6>{profile.nama_lengkap}</h6>
                  <p>{profile.jabatan}</p>
                </div>
                <p dangerouslySetInnerHTML={{ __html: profile.sejarah_pendidikan_terakhir }} />
                <p dangerouslySetInnerHTML={{ __html: profile.sejarah_karir }} />
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default DewanKomisaris;
