import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button, Upload, message, Radio } from 'antd';
import { PuffLoader } from 'react-spinners';
import axios from 'axios';
import './careerdetail.scss';
import ReCAPTCHA from 'react-google-recaptcha';
import { InboxOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Dragger } = Upload;

const CareerDetailId = ({ selectedLoker, open, setOpen }) => {
  const [careerData, setCareerData] = useState(null);
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [form] = Form.useForm();
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [gender, setGender] = useState('');
  const [summary, setSummary] = useState('');
  const [location, setLocation] = useState('');
  const [language] = useState('en');
  const [linkedinURL, setLinkedinURL] = useState('');
  const [resume, setResume] = useState(null);
  const [captchaDone, setCaptchaDone] = useState(false);

  useEffect(() => {
    if (selectedLoker) {
      setLoading(true);
      axios
        .get(`https://content.remala.id/wp-json/wp/v2/career/${selectedLoker}`)
        .then((res) => {
          const career = {
            id: res.data.id,
            name: res.data.title.rendered,
            content: res.data.content.rendered,
            department: res.data.acf.departement,
            location: res.data.acf.locations,
          };
          setCareerData(career.content);
          setTitle(career.name);
          setLoading(false);
          setShowForm(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [selectedLoker, open]);

  const handleApplyClick = () => {
    setShowForm(true);
  };

  const handleCancelClick = () => {
    setShowForm(false);
    form.resetFields();
  };

  const onFinish = async () => {
    if (!captchaDone) {
      message.error('Please complete the ReCAPTCHA.');
      return;
    }

    const formData = new FormData();
    formData.append('language', language);
    formData.append('fullName', fullName);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('gender', gender);
    formData.append('location', location);
    formData.append('linkedinURL', linkedinURL);
    formData.append('summary', summary);
    formData.append('resume', resume);
    formData.append('title', title);

    try {
      const response = await axios.post('https://remala.id:5001/send-email', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 200) {
        setOpen(false);
        form.resetFields();
        message.success(response.data);
      }
    } catch (error) {
      console.error('Error sending email:', error);
      message.error('Error sending email');
    }
  };

  const key = '6Lfoi9opAAAAAELrK2tR4iqD1mOL_ucB56czvXXB';
  function onChange() {
    setCaptchaDone(true);
  }

  const uploadProps = {
    name: 'file',
    multiple: false,
    listType: 'picture',
    maxCount: 1,
    accept: 'application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    beforeUpload: (file) => {
      const isPdfOrDocx = file.type === 'application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      if (!isPdfOrDocx) {
        message.error('You can only upload PDF or DOCX file!');
        return Upload.LIST_IGNORE;
      }
      setResume(file);
      return false;
    },
    onRemove: () => {
      setResume(null);
    },
    onChange: (info) => {
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <div>
      <Modal
        className="modalContainer"
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => {
          setOpen(false);
          form.resetFields();
          setShowForm(false);
        }}
        footer={null}
        width={1000}
      >
        {loading ? (
          <div className="loading-spinner">
            <PuffLoader color="#007bff" size={100} margin={2} />
          </div>
        ) : (
          <>
            {!showForm ? (
              <div>
                <div className="containerLoker">
                  <h1 className="headLoker">{title}</h1>
                  <div className="contentContainer" dangerouslySetInnerHTML={{ __html: careerData }} />
                </div>
                <div className="btnContainerJob">
                  <button className="btnApply" onClick={handleApplyClick}>
                    <span>Apply for This Job</span>
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <div className="containerLoker">
                  <h1 className="headLoker">Personal Information Form</h1>
                </div>
                <Form form={form} onFinish={onFinish} layout="vertical">
                  <Form.Item label="Full Name" name="fullName" rules={[{ required: true }]}>
                    <Input value={fullName} onChange={(e) => setFullName(e.target.value)} />
                  </Form.Item>
                  <Form.Item label="Email" name="email" rules={[{ required: true, type: 'email' }]}>
                    <Input value={email} onChange={(e) => setEmail(e.target.value)} />
                  </Form.Item>
                  <Form.Item label="Phone" name="phone" rules={[{ required: true }]}>
                    <Input value={phone} onChange={(e) => setPhone(e.target.value)} />
                  </Form.Item>
                  <Form.Item label="Gender" name="gender" rules={[{ required: true }]}>
                    <Radio.Group optionType="button" buttonStyle="solid" value={gender} onChange={(e) => setGender(e.target.value)}>
                      <Radio value="male">Male</Radio>
                      <Radio value="female">Female</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item label="Current Location" name="location" rules={[{ required: true }]}>
                    <Input value={location} onChange={(e) => setLocation(e.target.value)} />
                  </Form.Item>
                  <Form.Item label="LinkedIn URL" name="linkedinURL" rules={[{ required: true }]}>
                    <Input value={linkedinURL} onChange={(e) => setLinkedinURL(e.target.value)} />
                  </Form.Item>
                  <Form.Item label="About Yourself" name="aboutYourself" rules={[{ required: true }]}>
                    <TextArea rows={4} placeholder="Briefly describe yourself, such as your work experience and skills" maxLength={400} value={summary} onChange={(e) => setSummary(e.target.value)} />
                  </Form.Item>
                  <Form.Item label="Resume" name="resume" rules={[{ required: true }]}>
                    <Dragger {...uploadProps}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                      <p className="ant-upload-hint">Support for single file upload. Only PDF or DOCX files are allowed.</p>
                    </Dragger>
                  </Form.Item>
                  <Form.Item name="captcha" rules={[{ required: !captchaDone }]}>
                    <div className="captchaContainer">
                      <ReCAPTCHA sitekey={key} onChange={onChange} />
                    </div>
                  </Form.Item>

                  <div className="btnContainerJob">
                    <button className="btnApply" htmlType="submit">
                      Submit Application
                    </button>
                    <button className="btnCancel" onClick={handleCancelClick}>
                      Cancel
                    </button>
                  </div>
                </Form>
              </div>
            )}
          </>
        )}
      </Modal>
    </div>
  );
};

export default CareerDetailId;
