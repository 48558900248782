import React from 'react';
import CountUp from 'react-countup';
import './milestonehome.scss';

const Milestonehome = () => {
  return (
    <>
      <div className="containerHighlightMilestone" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2500">
        <div className="company-milestone" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2500">
          <h3>Company Milestone</h3>
          <p>Committed to Developing the Best Infrastructure</p>
          <div className="d-flex justify-content-around flex-wrap flex-md-nowrap" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2500">
            <div className="boxmile col-md-4 col-11 mt-md-0 mt-3" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2500">
              <div>
                <h2>
                  <CountUp className="amount" start={0} end={11000} duration={8} separator=" " />
                  KM
                </h2>
                <h4>Distributed Network</h4>
              </div>
            </div>
            <div className="boxmile col-md-4 col-11 mt-md-0 mt-3 mx-md-2" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2500">
              <div>
                <h2>
                  <CountUp className="amountyear" start={0} end={20} duration={13} separator=" " />
                  Th
                </h2>
                <h4 className="year" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2500">
                  Years
                </h4>
              </div>
            </div>
            <div className="boxmile col-md-4 col-11 mt-md-0 mt-3" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2500">
              <div>
                <h1>
                  <CountUp className="amount" start={0} end={170} duration={8} separator=" " />
                </h1>
                <h4>POP</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Milestonehome;
