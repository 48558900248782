import React, { useEffect, useState } from 'react';
import './news.scss';
import 'aos/dist/aos.css';
import { Pagination, AutoComplete, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import FooterId from '../footer/FooterId';
import FooterEn from '../footer/FooterEn';
import { ArrowRightOutlined, ArrowDownOutlined } from '@ant-design/icons';
import metaThumbnail from '../data/images/meta/homeimage.png';
import MetaDecorator from '../Util/MetaDecorator';
import axios from 'axios';
import { PuffLoader } from 'react-spinners';
import { format } from 'date-fns';
import id from 'date-fns/locale/id';

const news = require('../data/json/news.json');

const News = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const newsPerPage = 5;
  const [filterType, setFilterType] = useState('newer');
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const isIdPath = window.location.pathname.startsWith('/en');
  const [loading, setLoading] = useState(false);
  const [filteredNews, setFilteredNews] = useState([]);

  const indexOfLastNews = currentPage * newsPerPage;
  const indexOfFirstNews = indexOfLastNews - newsPerPage;

  useEffect(() => {
    try {
      setLoading(true);
      axios
        .get('https://content.remala.id/wp-json/wp/v2/news?order=desc&orderby=date')
        .then((res) => {
          const newData = res.data.map((item) => ({
            id: item.id,
            short_title: item.acf.short_title,
            tanggal: item.acf.tanggal,
            tanggalnew: format(new Date(item.acf.tanggal), 'EEEE, do MMMM yyyy'),
            thumbnail: item.acf.thumbnail,
            gagasan_utama: item.acf.gagasan_utama,
            status_posting: item.acf.status_posting,
            endpoint: item.acf.endpoint,
          }));
          newData.sort((a, b) => new Date(b.tanggal) - new Date(a.tanggal));
          setFilteredNews(newData.filter((news) => news.status_posting === true));

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      console.error('Terjadi kesalahan:', error);
    }
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleFilter = () => {
    const newFilterType = filterType === 'newer' ? 'older' : 'newer';
    setFilterType(newFilterType);

    const sortedNews =
      newFilterType === 'older' ? [...filteredNews].sort((a, b) => new Date(parseDate(a.tanggal)) - new Date(parseDate(b.tanggal))) : [...filteredNews].sort((a, b) => new Date(parseDate(b.tanggal)) - new Date(parseDate(a.tanggal)));

    window.scrollTo({ top: 0, behavior: 'smooth' });
    setFilteredNews(sortedNews);
    setCurrentPage(1);
  };

  const parseDate = (dateString) => {
    const [year, month, day] = dateString.split('/');
    return new Date(year, month - 1, day);
  };

  const handleReadMore = (id, endpoint) => {
    const encodedurl = encodeURIComponent(endpoint);
    navigate(`/en/news/${id}/${encodedurl}`);
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const filterNews = (newsItem) => {
    const { short_title, status_posting } = newsItem;
    const searchLowerCase = searchTerm.toLowerCase();
    return short_title.toLowerCase().includes(searchLowerCase) && status_posting === true;
  };

  return (
    <div>
      <MetaDecorator description={news.pageDescriptionEn} title={news.pageTitleEn} imageUrl={metaThumbnail} imageAlt={news.metaImageAltEn} />
      <div className="contentNews" data-aos="fade-down">
        <div className="news-content d-flex align-items-center justify-content-center">
          <div className="content-text">
            <h2 className="textHero" data-aos="fade-up">
              NEWS
            </h2>
          </div>
        </div>

        {loading ? (
          <div className="loading-spinner-news">
            <PuffLoader color="#007bff" size={100} margin={2} />
          </div>
        ) : (
          <div className="bottomNews">
            <div className="filter-search">
              <div className="filterTop">
                <div>
                  <Button type="default" icon={<ArrowDownOutlined rotate={filterType === 'newer' ? 180 : 0} />} onClick={handleFilter} />
                </div>
                <AutoComplete className="autoComplete" placeholder="Search News..." onChange={handleSearch} />
              </div>
              <div className="page"> Page {currentPage}</div>
            </div>
            <div className="middle">
              {filteredNews
                .filter(filterNews)
                .slice(indexOfFirstNews, indexOfLastNews)
                .map((news) => (
                  <div key={news.id} className="bodyNews">
                    <img src={news.thumbnail} />
                    <div className="desc">
                      <h2>{news.short_title}</h2>
                      <h5>{news.tanggalnew}</h5>
                      <p>{news.gagasan_utama}</p>
                      <hr />
                      <Link to={`/en/news/${news.id}/${news.short_title.replace(/ /g, '-')}`} onClick={() => handleReadMore(news.id, news.endpoint)}>
                        SEE DETAIL <ArrowRightOutlined />
                      </Link>
                    </div>
                  </div>
                ))}
            </div>
            <div className="pagination">
              <Pagination current={currentPage} pageSize={newsPerPage} total={filteredNews.length} onChange={handlePageChange} />
            </div>
          </div>
        )}

        <div className="footer">{isIdPath ? <FooterEn /> : <FooterId />}</div>
      </div>
    </div>
  );
};

export default News;
