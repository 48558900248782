import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import './newsdetail.scss';
import FooterId from '../../footer/FooterId';
import FooterEn from '../../footer/FooterEn';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FacebookShareButton, WhatsappShareButton, TwitterShareButton } from 'react-share';
import CopyToClipboard from 'react-copy-to-clipboard';
import Twitter from '../../../assets/news/twittericon.png';
import Facebook from '../../../assets/news/facebookicon.png';
import Share from '../../../assets/news/shareicon.png';
import Instagram from '../../../assets/news/instagramicon.png';
import Whatsapp from '../../../assets/news/waicon.png';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { PuffLoader } from 'react-spinners';

const NewsDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);
  const isIdPath = window.location.pathname.startsWith('/en');
  const [selectedNews, setSelectedNews] = useState('');
  const [contentDetail, setContentDetail] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://content.remala.id/wp-json/wp/v2/berita/${id}`)
      .then((res) => {
        const newsData = {
          id: res.data.id,
          title: res.data.title.rendered,
          content: res.data.content.rendered,
        };
        setContentDetail(newsData.content);
        setSelectedNews(newsData.title);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          navigate('/news');
        }
        setLoading(false);
      });
  }, [id, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);

    AOS.init({
      duration: 1300,
    });
  }, []);

  const shareUrl = window.location.href;

  const handleCopyToClipboard = () => {
    setCopied(true);
    toast.success('Link copied to clipboard!', {
      position: 'bottom-center',
      autoClose: 2000,
    });
  };

  const handleInstagramShare = () => {
    const instagramShareUrl = `https://www.instagram.com/?url=${encodeURIComponent(shareUrl)}`;

    window.open(instagramShareUrl, '_blank');
  };

  return (
    <div className="contentNewsDetail" data-aos="fade-down">
      <div className="newsDetail-content d-flex align-items-center justify-content-center">
        <div className="content-text">
          <h2 className="textHero" data-aos="fade-up">
            BERITA
          </h2>
        </div>
      </div>
      {loading ? (
        <div className="loading-spinner-news">
          <PuffLoader color="#007bff" size={100} margin={2} />
        </div>
      ) : (
        <div className="bottomNewsDetail">
          <h1>{selectedNews}</h1>
          <div className="shareContent">
            <p>Bagikan Artikel</p>
            <div className="shareIcon">
              <WhatsappShareButton url={shareUrl} className="linkIcon">
                <img src={Whatsapp} alt="" className="imgIcon" />
              </WhatsappShareButton>
              <FacebookShareButton url={shareUrl} className="linkIcon">
                <img src={Facebook} alt="" className="imgIcon" />
              </FacebookShareButton>
              <Link className="linkIcon" onClick={handleInstagramShare}>
                <img src={Instagram} alt="" className="imgIcon" />
              </Link>
              <TwitterShareButton url={shareUrl} className="linkIcon">
                <img src={Twitter} alt="" className="imgIcon" />
              </TwitterShareButton>
              <CopyToClipboard text={shareUrl} onCopy={handleCopyToClipboard}>
                <div className="linkIcon" style={{ cursor: 'pointer' }}>
                  <img src={Share} alt="" className="imgIcon" />
                </div>
              </CopyToClipboard>
            </div>
          </div>
          <div dangerouslySetInnerHTML={{ __html: contentDetail }} />
        </div>
      )}
      <div className="footer">{isIdPath ? <FooterEn /> : <FooterId />}</div>
    </div>
  );
};

export default NewsDetail;
