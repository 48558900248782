import React, { useState, useEffect } from 'react';
import './investor.scss';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import MetaDecorator from '../Util/MetaDecorator';
import metaThumbnail from '../data/images/meta/homeimage.png';

const investor = require('../data/json/investor.json');

const Investor = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeButton, setActiveButton] = useState('');

  useEffect(() => {
    // Set activeButton based on the initial URL path
    const pathArray = location.pathname.split('/').slice(2); // Get the path segments after /aboutus/
    const initialPath = pathArray.join('/');
    if (initialPath.startsWith('report')) {
      setActiveButton('report');
    } else {
      setActiveButton(initialPath);
    }
  }, [location.pathname]);

  const handleButtonClick = (componentName) => {
    setActiveButton(componentName);
    navigate(`/investor-relation/${componentName.toLowerCase()}`);

    // Check current scroll position
    const currentScrollPosition = window.scrollY;

    // Scroll only if current scroll position is less than 400px
    if (currentScrollPosition < 400) {
      window.scrollTo({
        top: 400,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 1300,
    });
  }, []);

  return (
    <div>
      <MetaDecorator description={investor.pageDescriptionId} title={investor.pageTitleId} imageUrl={metaThumbnail} imageAlt={investor.metaImageAltId} />
      <div className="contentInvestor" data-aos="fade-down">
        <div className="laporan-content d-flex align-items-center justify-content-center">
          <div className="content-text">
            <h2 className="textHero" data-aos="fade-left">
              MENJUNJUNG TINGGI INTEGRITAS
            </h2>
            <h2 className="textHero" data-aos="fade-right">
              DAN KOMITMEN DALAM BEKERJA
            </h2>
          </div>
        </div>
        <div className="buttonsInvestor">
          <div className="containerAbout">
            <button className={`btnMenu ${activeButton === 'stock-information/stock-volume' ? 'clicked' : ''}`} onClick={() => handleButtonClick('stock-information/stock-volume')}>
              Informasi Saham
            </button>
            <button className={`btnMenu ${activeButton === 'report' ? 'clicked' : ''}`} onClick={() => handleButtonClick('report/annual-report')}>
              Laporan - Laporan
            </button>
            <button className={`btnMenu ${activeButton === 'news/issuer-announcement' ? 'clicked' : ''}`} onClick={() => handleButtonClick('news/issuer-announcement')}>
              Kegiatan
            </button>
            <button className={`btnMenu ${activeButton === 'memo-information/memo-announcement' ? 'clicked' : ''}`} onClick={() => handleButtonClick('memo-information/memo-announcement')}>
              Informasi Memo
            </button>
            <button className={`btnMenu ${activeButton === 'announcement/public-announcement' ? 'clicked' : ''}`} onClick={() => handleButtonClick('announcement/public-announcement')}>
              Pengumuman
            </button>
          </div>
          <div className="isi-content-investor">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Investor;
