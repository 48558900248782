import React, { useEffect } from 'react';
import FooterId from '../../../footer/FooterId';
import FooterEn from '../../../footer/FooterEn';
import './contact.scss';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Contact = () => {
  const isIdPath = window.location.pathname.startsWith('/en');

  useEffect(() => {
    AOS.init({
      duration: 1300,
    });
  }, []);

  return (
    <>
      <div className="containerContact" data-aos="fade-up">
        <div className="location">
          <div className="front">
            <LocationOnIcon className="icon" />
            <p>Location</p>
          </div>
          <div className="back">
            <h1>Graha mustika ratu</h1>
            <p>Jl. Gatot Subroto No 74-75, RT 02/ RW 01. Menteng Dalam, Kec. Tebet, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12870</p>
          </div>
        </div>
        <div className="phone">
          <div className="front">
            <PhoneInTalkIcon className="icon" />
            <p>Phone</p>
          </div>
          <div className="back">
            <p>(021) 83708889</p>
          </div>
        </div>
        <div className="email">
          <div className="front">
            <AttachEmailIcon className="icon" />
            <p>E-mail</p>
          </div>
          <div className="back">
            <p>corporatesecretary@remala.id</p>
          </div>
        </div>
      </div>
      <div className="footer">{isIdPath ? <FooterEn /> : <FooterId />}</div>
    </>
  );
};

export default Contact;
