import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import './swiper.scss';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';

const SimpleSlider = () => {
  const slidesPerView = window.innerWidth > 860 ? 4 : 1;
  const [imageSwipers, setImageSwipers] = useState([]);
  const [endPoints, setEndPoints] = useState([]);
  const [ids, setIds] = useState([]);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    axios
      .get(`https://content.remala.id/wp-json/wp/v2/news`)
      .then((res) => {
        const filteredData = res.data.filter((item) => item.acf.status_posting === true);
        const images = [];
        const endpoints = [];
        const ids = [];

        filteredData.forEach((item) => {
          const imageData = item.acf.potrait_home;
          images.push(imageData !== false ? imageData : 'false');
          endpoints.push(item.acf.short_title.replace(/ /g, '-'));
          ids.push(item.id);
        });

        setImageSwipers(images);
        setEndPoints(endpoints);
        setIds(ids);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="containerSwiper">
      <div className="slideShow">
        <h3 data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2500">
          About Remala Abadi
        </h3>
        <p data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2500">
          Company Activities
        </p>
        <Swiper
          slidesPerView={slidesPerView}
          spaceBetween={10}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="2500"
        >
          {imageSwipers.map(
            (image, index) =>
              image !== 'false' && (
                <SwiperSlide key={index}>
                  <Link to={`/en/news/${ids[index]}/${endPoints[index]}`}>
                    <img src={image} alt="#" className="imgSwiper" />
                  </Link>
                </SwiperSlide>
              )
          )}
        </Swiper>
      </div>
    </div>
  );
};

export default SimpleSlider;
